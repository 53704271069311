<!-- USES -->
<!-- Audience ->  dashboard/followers -->
<!-- Following -> following/users (users/artists) -->
<template>
    <div class="grid_table audience">
        <div class="item_row heading">
            <div class="ava">
                <div class="view_list"></div>
                <div class="view_map"></div>
            </div>
            <div class="name">
                <span>Name</span>
                <div class="sort">
                    <div class="asc"></div>
                    <div class="desc"></div>
                </div>
            </div>
            <div class="location">Location</div>
<!--            <div class="supporter">-->
<!--                <span>Supporter</span>-->
<!--                <div class="sort">-->
<!--                    <div class="asc"></div>-->
<!--                    <div class="desc"></div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="followers">
                <span>Followers</span>
                <div class="sort">
                    <div class="asc"></div>
                    <div class="desc"></div>
                </div>
            </div>
            <div class="more">
                <span></span>
            </div>
        </div>

	    <template v-if="FOLLOW_LOADER">
		    <v-skeleton-loader
			    type="image"
			    class="table_item_row"
			    v-for="index in 31"
			    :key="index"
		    ></v-skeleton-loader>
	    </template>


	    <template v-if="tableData && tableData.length">
            <div class="item_row data" v-for="item of tableData" :key="'A' + item.id + dynamicKey" @click="handleRowClick(item)">
                <div class="ava">
                    <template v-if="data && data === 'users' || data === 'artists' || data === 'followers'">
                        <img v-if="item.photo && item.photo.thumbnail"
                             v-bind:srcset="`${item.photo.thumbnail['50x50']}?cache=${cacheKey} 1x, ${item.photo.thumbnail['50x50@2x']}?cache=${cacheKey} 2x`"
                             :src="`${item.photo.thumbnail['50x50']}?cache=${cacheKey}`" alt="photo">
                    </template>
                    <div v-if="!item.photo || !item.photo.thumbnail" class="default_ava"></div>
                </div>
                <div class="name">
                    <template v-if="data === 'users' || data === 'followers'">
                        <span class="user_name" @click="redirectToUserProfile(item.id, item.fullName)">
                            {{ item.fullName }}
                        </span>
                    </template>
                    <template v-if="data === 'artists'">
                        <div class="artist_name" @click="redirectToArtistPage(item.id, item.fullName)">
                            {{ item.fullName }}
                        </div>
                    </template>
                </div>
                <div class="location">
	                <template v-if="item.address">{{ item.address }}</template>
	                <template v-else>-</template>
                </div>
<!--                <div class="supporter">-</div>-->

                <div class="followers">
	                <template v-if="data === 'followers'">{{ item.followersCount }}</template>
	                <template v-if="data === 'users'">{{ item.followers }}</template>
	                <template v-if="data === 'artists'">{{ item.followers }}</template>
                </div>


                <div class="more">
                    <v-menu
                            transition="slide-y-transition"
                            :close-on-content-click="false"
                            :nudge-width="120"
                            offset-y
                            nudge-left="60"
                            nudge-bottom="15"
                            origin="top center"
                            content-class="menu-pointer charcoal">
                        <template v-slot:activator="{ on, attrs }">
                            <button type="button" v-ripple class="more_menu" v-bind="attrs" v-on="on"></button>
                        </template>
                        <v-list>
                            <v-list-item v-ripple>
                                <button type="button" v-if="data !== 'followers'" @click="unfollow(item.id)">Unfollow</button>
                            </v-list-item>
<!--                            <v-list-item v-ripple>-->
<!--                                <button type="button">item 2</button>-->
<!--                            </v-list-item>-->
<!--                            <v-list-item v-ripple disabled>-->
<!--                                <button type="button">item 3</button>-->
<!--                            </v-list-item>-->
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </template>


        <template v-if="!FOLLOW_LOADER && !tableData.length">
            <nothingHereYet></nothingHereYet>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import nothingHereYet from "@/components/small/nothingHereYet";

export default {
    name: "audienceTable",
    props: ['tableData', 'data'],
    components: {
        nothingHereYet
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            loader: false,
            dynamicKey: 0,
            cacheKey: new Date().getTime(),
        }
    },
    computed: {
        ...mapGetters(['FOLLOW_LOADER']),
    },
    mounted() {
        this.loader = true;
    },
	methods: {
		...mapActions(['ARTIST_UNFOLLOW', 'UNFOLLOW']),

		redirectToArtistPage(artistId, artistName) {
			this.$router.push({
				name: 'artist-profile', 
				params: {
					id: artistId, 
					name: window.toHumanFormat(artistName)
				}
			});
		},
		redirectToUserProfile(userID, userName) {
			this.$router.push({
				name: 'user-profile', 
				params: {
					id: userID, 
					name: window.toHumanFormat(userName)
				}
			});
		},
		unfollow(itemID) {
			console.log('unfollow', this.data, itemID);
			// this.data // artists // users

			if (this.data === 'artists') {
				this.ARTIST_UNFOLLOW(itemID)
					.then(result => {
						if (result === 'success') {
							let index = this.tableData.findIndex((el) => el.id === itemID);
							if (index !== -1) {
								this.tableData.splice(index, 1);
							}
						} else {
							// this.errorDialog = true;
						}
					})
					.catch(err => {
						// this.errorDialog = true;
						console.error(`artist unfollow, ${err}`)
					})
					.finally(() => {
						// this.followBtnLoader = false
					});
			}
			if (this.data === 'users') {
				this.UNFOLLOW(itemID)
					.then(result => {
						if (result === 'success') {
							let index = this.tableData.findIndex((el) => el.id === itemID);
							if (index !== -1) {
								this.tableData.splice(index, 1);
							}
						} else {
							// this.errorDialog = true;
						}
					})
					.catch(err => {
						// this.errorDialog = true;
						console.error(`user unfollow, ${err}`)
					})
					.finally(() => {
						// this.followBtnLoader = false
					});
			}

		},
		handleRowClick(item) {
			if (window.innerWidth <= 479) {
				if (this.data === 'users') {
					this.redirectToUserProfile(item.id, item.fullName);
				} else if (this.data === 'artists') {
					this.redirectToArtistPage(item.id, item.fullName);
				} else if (this.data === 'followers') {
					this.redirectToUserProfile(item.id, item.fullName);
				}
			}
		},
	}
}
</script>

<style lang="scss">
@media screen and (max-width: 479px) {
    .grid_table .item_row.data {
        cursor: pointer !important;
        position: relative;
        transition: background-color 0.2s ease;
        -webkit-tap-highlight-color: transparent;
    }

    .grid_table .item_row.data:active {
        background-color: rgba(255, 255, 255, 0.05) !important;
    }
}
</style>
